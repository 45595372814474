import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-page403',
	templateUrl: './page403.component.html',
	styleUrls: ['./page403.component.scss']
})
export class Page403Component {
	public router = inject( Router );
	public ts = inject( TranslateService );
	local;
	idUserType;
	tryToLogin(){
		this.router.navigateByUrl('auth/login')
	}
}
