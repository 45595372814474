import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { EncryptDataService } from 'src/app/core/security/encrypt-data.service';
@Component({
	selector: 'app-page503',
	templateUrl: './page503.component.html',
	styleUrls: ['./page503.component.scss']
})
export class Page503Component implements OnInit{
	public router = inject( Router );
	public ts = inject( TranslateService );
	public location = inject( Location );
	public securityLocalStorage = inject( EncryptDataService );
	local;
	idUserType;
	ngOnInit(): void {
		this.local = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
		this.idUserType = this.local.result.id_user_type;
	}
	continueLocker(){
		if(this.idUserType === 6){
			this.router.navigateByUrl('warehouse/locker');
		}
		else if(this.idUserType === 7){
			this.router.navigateByUrl('vivipost/locker');
		}
		else if(this.idUserType === 9){
			this.router.navigateByUrl('superadmin/locker');
		}
		else if(this.idUserType === 10){
			this.router.navigateByUrl('carriers/locker');
		}
	}
	goBack(): void {
		this.location.back();
	}
}
